import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import { CSVLink, CSVDownload } from 'react-csv';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  dateFormat,
  dateGreaterOrEqual,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import PdfGenerator from '../../components/PdfGenerator';
import moment from 'moment';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  padding: 4px;
  margin-bottom: 10px;
`;

const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const PackedStockReport = () => {
  const [itemData, setItemData] = useState();
  const [showDownload, setShowDownload] = useState(false);
  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);
  const state = useContext(GlobalStateData);
  const pdfTitles = [
    'Customer',
    'Sort',
    'Bale',
    'Date',
    'Pk Mts',
    'Snd Mts',
    'Shrt Len',
    'Sec Mts',
    'Fents Mts',
    'Rags Mts',
    'Oth Mts',
    'Fin Wid',
    'Prc Col',
    'Blend',
    'Nt Wt',
    'Gr Wt',
    'Pieces',
    'POC No.',
    'Lt. No.',
  ];
  const [pdfContent, setPdfContent] = useState([[]]);
  const [pdfData, setPdfData] = useState({});
  // useEffect(() => {
  //   setPdfData({
  //     toDate: to,
  //     tableTitles: pdfTitles,
  //     tableContents: pdfContent,
  //     documentTitle: 'Packed Stock',
  //   });
  // }, [pdfContent]);
  useEffect(() => {
    authCheck(state.authData);
    getCustomers();
    getSorts('');
  }, []);

  useEffect(() => {
    if (tableData.length) {
      setShowDownload(true);
    } else {
      setShowDownload(false);
    }
  }, [tableData]);

  const localeNumGetter = (num: any) => {
    num = +num;
    return num.toLocaleString('en-IN', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  };

  const postApi = () => {
    const postData = {
      data: {
        customer,
        customerSort,
        to,
      },
    };
    let validate = requireCheck(postData);

    if (!validate) {
      return false;
    }
    let cust = customer.toString();
    let sort = customerSort.toString();
    showLoader();
    axios
      .get(
        baseUri() + 'grey-receipt-advice/packed-stock-report?users=' + cust + '&customersSort=' + sort + '&upto=' + to,
        {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        hideLoader();
        let totalBales = 0,
          totalMeters = 0,
          totalPackedMts = 0,
          totalSoundMeters = 0,
          totalShortLenMeters = 0,
          totalSecondsMeters = 0,
          totalFentsMeters = 0,
          totalRagsMeters = 0,
          totalOtherMeters = 0,
          totalFinWidth = 0,
          totalNtWeight = 0,
          totalGrWeight = 0,
          totalPieces = 0;
        const pdfData: Array<Array<any>> = [];
        let prevName = '';
        let prevSortName = '';
        let sortTotCount = 1;
        let sortNameTotal = {
          totalBales: 0,
          totalMeters: 0,
          totalPackedMts: 0,
          totalSoundMeters: 0,
          totalShortLenMeters: 0,
          totalSecondsMeters: 0,
          totalFentsMeters: 0,
          totalRagsMeters: 0,
          totalOtherMeters: 0,
          totalFinWidth: 0,
          totalNtWeight: 0,
          totalGrWeight: 0,
          totalPieces: 0,
        };
        let customerTotal = {
          totalBales: 0,
          totalMeters: 0,
          totalPackedMts: 0,
          totalSoundMeters: 0,
          totalShortLenMeters: 0,
          totalSecondsMeters: 0,
          totalFentsMeters: 0,
          totalRagsMeters: 0,
          totalOtherMeters: 0,
          totalFinWidth: 0,
          totalNtWeight: 0,
          totalGrWeight: 0,
          totalPieces: 0,
        };

        response.data.data.forEach(
          (data: {
            Customer_Name: string;
            Customer_Sort: string;
            Bale_Num: number;
            Pckd_Date: string;
            Pckd_Mts: number;
            Sound_Mts: number;
            Short_Length_Mts: number;
            Seconds_Mts: number;
            Fents_Mts: number;
            Rags_Mts: number;
            Others_Mts: number;
            fin_width: number;
            Prcs_color: string;
            Blend: string;
            Nt_Wt: number;
            Gr_Wt: number;
            Piecs: number;
            POC_Num: number;
            Lot_Num: number;
          }) => {
            totalBales += 1;
            totalPackedMts += +data.Pckd_Mts;
            totalSoundMeters += +data.Sound_Mts;
            totalShortLenMeters += +data.Short_Length_Mts;
            totalSecondsMeters += +data.Seconds_Mts;
            totalFentsMeters += +data.Fents_Mts;
            totalRagsMeters += +data.Rags_Mts;
            totalOtherMeters += +data.Others_Mts;
            totalFinWidth += +data.fin_width;
            totalNtWeight += +data.Nt_Wt;
            totalGrWeight += +data.Gr_Wt;
            totalPieces += 1;
            let currName = '  ';
            let currSortName = '  ';
            if (prevSortName === data.Customer_Sort) {
              sortTotCount++;
              sortNameTotal.totalPackedMts += +data.Pckd_Mts;
              sortNameTotal.totalSoundMeters += +data.Sound_Mts;
              sortNameTotal.totalShortLenMeters += +data.Short_Length_Mts;
              sortNameTotal.totalSecondsMeters += +data.Seconds_Mts;
              sortNameTotal.totalFentsMeters += +data.Fents_Mts;
              sortNameTotal.totalRagsMeters += +data.Rags_Mts;
              sortNameTotal.totalOtherMeters += +data.Others_Mts;
              sortNameTotal.totalFinWidth += +data.fin_width;
              sortNameTotal.totalNtWeight += +data.Nt_Wt;
              sortNameTotal.totalGrWeight += +data.Gr_Wt;
              sortNameTotal.totalPieces += 1;
            } else if (prevSortName !== data.Customer_Name) {
              currSortName = data.Customer_Sort;
              if (sortTotCount > 1) {
                let currPdfData = [
                  '  ',
                  '  ',
                  '  ',
                  'Sort Total',
                  localeNumGetter(sortNameTotal.totalPackedMts),
                  localeNumGetter(sortNameTotal.totalSoundMeters),
                  localeNumGetter(sortNameTotal.totalShortLenMeters),
                  localeNumGetter(sortNameTotal.totalSecondsMeters),
                  localeNumGetter(sortNameTotal.totalFentsMeters),
                  localeNumGetter(sortNameTotal.totalRagsMeters),
                  localeNumGetter(sortNameTotal.totalOtherMeters),
                  localeNumGetter(sortNameTotal.totalFinWidth),
                  '  ',
                  '  ',
                  localeNumGetter(sortNameTotal.totalNtWeight),
                  localeNumGetter(sortNameTotal.totalGrWeight),
                  sortNameTotal.totalPieces,
                  '  ',
                  '  ',
                ];
                pdfData.push(currPdfData);
              }
              prevSortName = data.Customer_Sort;
              sortTotCount = 1;
              sortNameTotal = {
                totalBales: 1,
                totalMeters: 0,
                totalPackedMts: data.Pckd_Mts,
                totalSoundMeters: data.Sound_Mts,
                totalShortLenMeters: data.Short_Length_Mts,
                totalSecondsMeters: data.Seconds_Mts,
                totalFentsMeters: data.Fents_Mts,
                totalRagsMeters: data.Rags_Mts,
                totalOtherMeters: data.Others_Mts,
                totalFinWidth: data.fin_width,
                totalNtWeight: data.Nt_Wt,
                totalGrWeight: data.Gr_Wt,
                totalPieces: 1,
              };
            }

            if (prevName !== data.Customer_Name) {
              currName = data.Customer_Name;
              prevName = currName;
              if (!(customerTotal.totalPackedMts === 0 && customerTotal.totalSoundMeters === 0))
                pdfData.push([
                  '  ',
                  '  ',
                  '  ',
                  'Customer Total',
                  localeNumGetter(customerTotal.totalPackedMts),
                  localeNumGetter(customerTotal.totalSoundMeters),
                  localeNumGetter(customerTotal.totalShortLenMeters),
                  localeNumGetter(customerTotal.totalSecondsMeters),
                  localeNumGetter(customerTotal.totalFentsMeters),
                  localeNumGetter(customerTotal.totalRagsMeters),
                  localeNumGetter(customerTotal.totalOtherMeters),
                  localeNumGetter(customerTotal.totalFinWidth),
                  '  ',
                  '  ',
                  localeNumGetter(customerTotal.totalNtWeight),
                  localeNumGetter(customerTotal.totalGrWeight),
                  customerTotal.totalPieces,
                  '  ',
                  '  ',
                ]);
              customerTotal = {
                totalBales: 1,
                totalMeters: 0,
                totalPackedMts: data.Pckd_Mts,
                totalSoundMeters: data.Sound_Mts,
                totalShortLenMeters: data.Short_Length_Mts,
                totalSecondsMeters: data.Seconds_Mts,
                totalFentsMeters: data.Fents_Mts,
                totalRagsMeters: data.Rags_Mts,
                totalOtherMeters: data.Others_Mts,
                totalFinWidth: data.fin_width,
                totalNtWeight: data.Nt_Wt,
                totalGrWeight: data.Gr_Wt,
                totalPieces: 1,
              };
            } else {
              customerTotal.totalPackedMts += +data.Pckd_Mts;
              customerTotal.totalSoundMeters += +data.Sound_Mts;
              customerTotal.totalShortLenMeters += +data.Short_Length_Mts;
              customerTotal.totalSecondsMeters += +data.Seconds_Mts;
              customerTotal.totalFentsMeters += +data.Fents_Mts;
              customerTotal.totalRagsMeters += +data.Rags_Mts;
              customerTotal.totalOtherMeters += +data.Others_Mts;
              customerTotal.totalFinWidth += +data.fin_width;
              customerTotal.totalNtWeight += +data.Nt_Wt;
              customerTotal.totalGrWeight += +data.Gr_Wt;
              customerTotal.totalPieces += 1;
            }

            pdfData.push([
              currName,
              currSortName,
              data.Bale_Num,
              moment(data.Pckd_Date).format('DD.MM.YY'),
              localeNumGetter(data.Pckd_Mts),
              localeNumGetter(data.Sound_Mts),
              localeNumGetter(data.Short_Length_Mts),
              localeNumGetter(data.Seconds_Mts),
              localeNumGetter(data.Fents_Mts),
              localeNumGetter(data.Rags_Mts),
              localeNumGetter(data.Others_Mts),
              localeNumGetter(data.fin_width),
              data.Prcs_color,
              data.Blend[0],
              localeNumGetter(data.Nt_Wt),
              localeNumGetter(data.Gr_Wt),
              data.Piecs,
              data.POC_Num,
              data.Lot_Num,
            ]);
          },
        );

        if (sortTotCount > 1) {
          pdfData.push([
            '  ',
            '  ',
            '  ',
            'Sort Total',
            localeNumGetter(sortNameTotal.totalPackedMts),
            localeNumGetter(sortNameTotal.totalSoundMeters),
            localeNumGetter(sortNameTotal.totalShortLenMeters),
            localeNumGetter(sortNameTotal.totalSecondsMeters),
            localeNumGetter(sortNameTotal.totalFentsMeters),
            localeNumGetter(sortNameTotal.totalRagsMeters),
            localeNumGetter(sortNameTotal.totalOtherMeters),
            localeNumGetter(sortNameTotal.totalFinWidth),
            '  ',
            '  ',
            localeNumGetter(sortNameTotal.totalNtWeight),
            localeNumGetter(sortNameTotal.totalGrWeight),
            sortNameTotal.totalPieces,
            '  ',
            '  ',
          ]);
        }

        pdfData.push([
          '  ',
          '  ',
          '  ',
          'Customer Total',
          localeNumGetter(customerTotal.totalPackedMts),
          localeNumGetter(customerTotal.totalSoundMeters),
          localeNumGetter(customerTotal.totalShortLenMeters),
          localeNumGetter(customerTotal.totalSecondsMeters),
          localeNumGetter(customerTotal.totalFentsMeters),
          localeNumGetter(customerTotal.totalRagsMeters),
          localeNumGetter(customerTotal.totalOtherMeters),
          localeNumGetter(customerTotal.totalFinWidth),
          '  ',
          '  ',
          localeNumGetter(customerTotal.totalNtWeight),
          localeNumGetter(customerTotal.totalGrWeight),
          customerTotal.totalPieces,
          '  ',
          '  ',
        ]);

        let grandTotal = {
          'Pk Mts': localeNumGetter(totalPackedMts),
          'Snd Mts': localeNumGetter(totalSoundMeters),
          'Shrt Len': localeNumGetter(totalShortLenMeters),
          'Sec Mts': localeNumGetter(totalSecondsMeters),
          'Fents Mts': localeNumGetter(totalFentsMeters),
          'Rags Mts': localeNumGetter(totalRagsMeters),
          'Oth Mts': localeNumGetter(totalOtherMeters),
          'Fin Wid': localeNumGetter(totalFinWidth),
          'Nt Wt': localeNumGetter(totalNtWeight),
          'Gr Wt': localeNumGetter(totalGrWeight),
          'Bale Count': totalPieces,
        };
        setPdfData((prev) => {
          return {
            ...prev,
            tableTitles: pdfTitles,
            tableContents: pdfData,
            documentTitle: 'Packed Stock',
            to: moment(to).format('DD/MM/YYYY'),
            total: grandTotal,
          };
        });
        setItemData(response.data.data);
        if (tableFilter) {
          filterChange(tableFilter, response.data.data);
        } else {
          setTableData(response.data.data);
        }
        hideLoader();
      })
      .catch((error) => {
        //return  error;
        console.log(error);
      });
  };

  const [customer, setCustomer] = useState([]);
  const [defaultCustomer, setDefaultCustomer] = useState([{ label: 'All', value: '' }]);
  const [userOptions, setUserOptions] = useState([]);
  const [customerSort, setCustomerSort] = useState([]);
  const [defaultCustomerSort, setDefaultCustomerSort] = useState([{ label: 'All', value: '' }]);
  const [sortOptions, setSortOptions] = useState([]);
  const [to, setTo] = useState(dateFormat(new Date().toISOString()));

  const handleChange = (event) => {
    resetRequired(event);
    switch (event.target.name) {
      case 'to':
        setTo(event.target.value);
    }
  };

  const handleCustomerChange = (event) => {
    let selected = [];
    let fliterStr = [];
    if (event.length == 0) {
      event = [{ label: 'All', value: '' }];
    }
    if (event.length > 1 && event[0].label == 'All') {
      event.splice(0, 1);
    }
    if (event.length > 1 && event[event.length - 1].label == 'All') {
      event.splice(0, event.length - 1);
    }
    event.forEach(function (item, index) {
      if (item.value) {
        selected.push(item.value);
        fliterStr.push(`filters[user][id][$in][${index}]=${item.value}`);
      }
    });
    setCustomer(selected);
    setDefaultCustomer(event);
    pullDownReset('customer');
    setCustomerSort([]);
    setDefaultCustomerSort([{ label: 'All', value: '' }]);
    setSortOptions([]);
    getSorts(fliterStr.join('&'));
  };

  const handleCustomerSortChange = (event) => {
    let selected = [];
    if (event.length == 0) {
      event = [{ label: 'All', value: '' }];
    }
    if (event.length > 1 && event[0].label == 'All') {
      event.splice(0, 1);
    }
    if (event.length > 1 && event[event.length - 1].label == 'All') {
      event.splice(0, event.length - 1);
    }
    event.forEach(function (item, index) {
      selected.push(item.value);
    });
    setCustomerSort(selected);
    setDefaultCustomerSort(event);
    pullDownReset('customerSort');
  };

  const clearFormData = () => {
    getSorts('');
    setTo('');
    setCustomer([]);
    setDefaultCustomer([{ label: 'All', value: '' }]);
    setCustomerSort([]);
    setDefaultCustomerSort([{ label: 'All', value: '' }]);
    resetRequired();
    document.getElementsByClassName('to')[0].classList.remove('require');
  };

  const getCustomers = () => {
    axios
      .get(baseUri() + 'users?filters[userType][$eq]=4', {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [{ label: 'All', value: '' }];
        response.data.forEach(function (item, index) {
          options.push({ label: item.userCode + ' - ' + item.name, value: item.id });
        });
        setUserOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getSorts = (query) => {
    setCustomerSort([]);
    showLoader();
    axios
      .get(baseUri() + `user-sorts?${query}`, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [{ label: 'All', value: '' }];
        response.data.data.forEach(function (item, index) {
          options.push({ label: item.attributes.sortName, value: item.id });
        });
        setSortOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const columns = [
    {
      name: 'Customer',
      selector: (row) => `${row.Customer_Name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Customer Sort',
      selector: (row) => `${row.Customer_Sort}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Bale Num',
      selector: (row) => `${row.Bale_Num}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'PKD. Date',
      selector: (row) => `${dateFormat(row.Pckd_Date)}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'PKD Mts',
      selector: (row) => row.Pckd_Mts,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Sound Mts',
      selector: (row) => row.Sound_Mts,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Short Len Mts',
      selector: (row) => row.Short_Length_Mts,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Second Mts',
      selector: (row) => row.Seconds_Mts,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Fents Mts',
      selector: (row) => row.Fents_Mts,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Rags Mts',
      selector: (row) => row.Rags_Mts,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Others Mts',
      selector: (row) => row.Others_Mts,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Fin Width',
      selector: (row) => row.fin_width,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Process Color',
      selector: (row) => row.Prcs_color,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Blend',
      selector: (row) => row.Blend,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Nt Wt',
      selector: (row) => row.Nt_Wt,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Gr Wt',
      selector: (row) => row.Gr_Wt,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Piecs',
      selector: (row) => row.Piecs,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'POC Num',
      selector: (row) => row.POC_Num,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Lot_Num',
      selector: (row) => row.Nt_Wt,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Bill_Code',
      selector: (row) => row.Bill_Code,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Remarks',
      selector: (row) => row.Remarks,
      sortable: true,
      maxWidth: '45%',
    },
  ];
  const filterColumns = [
    'Customer_Name',
    'Customer_Sort',
    'Bale_Num',
    'Pckd_Date',
    'Pckd_Mts',
    'Sound_Mts',
    'Short_Length_Mts',
    'Seconds_Mts',
    'Fents_Mts',
    'Rags_Mts',
    'Others_Mts',
    'fin_width',
    'Prcs_color',
    'Blend',
    'Nt_Wt',
    'Gr_Wt',
    'Piecs',
    'POC_Num',
    'Lot_Num',
    'Bill_Code',
    'Remarks'
  ];

  const filterChange = (searchInput, newData = false) => {
    setTableFilter(searchInput);
    let available = [];
    if (newData) {
      available = newData;
    } else {
      available = itemData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns, true);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };

  return (
    <>
      <SEO title="Packed Stock Report" />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header> Packed Stock Report</header>
            <CardBody className="cardbody">
              <Row>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Customer <span className="red">*</span>
                  </label>
                  <SelectField
                    fullWidth
                    onChange={(e) => handleCustomerChange(e)}
                    options={userOptions}
                    id="customer"
                    name="customer"
                    placeholder="Select Customer *"
                    value={defaultCustomer}
                    isMulti
                    multiple
                    className="pull-down multi-select"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Customer Sort</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => handleCustomerSortChange(e)}
                    options={sortOptions}
                    id="customerSort"
                    name="customerSort"
                    placeholder="Select Customer Sort"
                    value={defaultCustomerSort}
                    isMulti
                    multiple
                    className="pull-down multi-select"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    As on Date <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="to"
                      placeholder="date"
                      onChange={(e) => handleChange(e)}
                      value={to}
                      required
                      className="to require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }}>
                  <Button status="Success" type="button" className="btnrad" shape="" halfWidth onClick={postApi}>
                    Get Report
                  </Button>
                  &nbsp; &nbsp;
                  <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Packed Stock Report</header>
            <CardBody>
              {showDownload && (
                <p
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <PdfGenerator documentTitle="packed-stock-report" data={pdfData} />
                  <CSVLink data={tableData}>Download Excel</CSVLink>
                </p>
              )}
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => filterChange(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PackedStockReport;
